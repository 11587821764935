<template>
    <el-row>
        <el-col :span="12">
            <el-scrollbar>
                <div>
                    <el-image :src="world" />

                    <div>
                        <el-text>Crea Mailings</el-text>
                        <el-divider direction="vertical" />
                        <el-text>Comunica</el-text>
                        <el-divider direction="vertical" />
                        <el-text>Fideliza</el-text>
                        <el-divider direction="vertical" />
                        <el-text>Crece</el-text>
                    </div>
                </div>
            </el-scrollbar>
        </el-col>
        <el-col :span="12" justify="center">
            <el-scrollbar>
                <div>
                    <el-image :src="logo_big" />

                    <el-form @submit.native.prevent="submit" :model="model" :rules="rules" ref="form">
                        <div>
                            <el-form-item prop="username">
                                <el-input size="large" placeholder="Ingrese usuario" v-model="model.username" :prefix-icon="User" />
                            </el-form-item>
                            <el-form-item prop="password">
                                <el-input size="large" placeholder="Ingrese contraseña" v-model="model.password" :prefix-icon="Lock" type="password" show-password />
                            </el-form-item>
                        </div>
                        <el-button type="primary" native-type="submit" round>Iniciar sesión</el-button>
                    </el-form>
                </div>
            </el-scrollbar>
        </el-col>
    </el-row>
</template>

<script setup>
    import { reactive, ref, watch } from "vue";
    import { ElLoading, ElMessage } from "element-plus";
    import { User, Lock } from "@element-plus/icons-vue";
    import logo_big from "@/assets/svg/logo_big.svg";
    import world from "@/assets/img/world.webp";

    /* refs */
    const form = ref(null);

    /* submit */
    const submit = () => {
        form.value.validate(async (valid) => {
            if (valid) {
                const loading = ElLoading.service();

                const formData = new FormData();
                formData.append("username", model.username);
                formData.append("password", model.password);

                let res = await fetch("/login", {
                    method: "post",
                    body: formData,
                });

                if (res.status === 200) {
                    res = await res.json();

                    window.location = "/";
                } else {
                    res = await res.json();

                    loading.close();

                    ElMessage({
                        message: res.error,
                        type: "error",
                    });
                }
            } else {
                ElMessage({
                    message: "Verifique los datos del formulario",
                    type: "error",
                });
            }
        });
    };

    /* model */
    const model = reactive({
        username: "",
        password: "",
    });

    /* rules */
    const rules = reactive({
        username: {
            required: true,
            message: "Ingrese usuario",
        },
        password: {
            required: true,
            message: "Ingrese contraseña",
        },
    });
</script>
